import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from '@components';
import styled from 'styled-components';
import { Main } from '@styles';

const Container = styled(Main)`
  h1 {
    font-size: 5vw;
  }
  img {
    max-width: 300px;
  }
`;

const SuccessPage = ({ location }) => (
  <Layout location={location}>
    <Container>
      <h1>Thanks for applying!</h1>
      <p>You should receive an email back from us within the next few days.</p>
      <img src="https://media.giphy.com/media/13ByqbM0hgfN7y/giphy.gif" alt="Corgi tippy taps" />
    </Container>
  </Layout>
);

SuccessPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default SuccessPage;
